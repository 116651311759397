import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
  loadChildren: () =>import('./modules/features/auth-pages/auth-pages.module').then(
    (m) => m.AuthPagesModule)
  },
  {
    path: '',
    canLoad:[AuthGuard],

    loadChildren: () =>import('../app/modules/features/main-layout.module').then(
      (m) => m.MainLayoutModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
