import { Injectable } from '@angular/core';
import { Observable, shareReplay, Subject, Subscription, switchMap, takeUntil, timer } from 'rxjs';
import { GET, REFRESH_TOKEN } from '../../core/constants/constants';
import { ApiService } from '../../core/http/api.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  

  private tokenCache!: Observable<any>;
  private readonly CACHE_SIZE = 1;
  private readonly REFRESH_INTERVAL = 115 * 60 * 1000; // 115 min refresh token time
  userToken!: string;
  private tokenSubscription!: Subscription;
  private timer!: Subject<boolean>;

  constructor(private _service: ApiService, private _ls: LocalStorageService) {}

  set token(token: string) {
    this.userToken = token;
 
    sessionStorage.getItem('token')
    if (token && !this.tokenCache) {
      const stopTimer = new Subject<boolean>();
      this.timer = stopTimer;
      const myTimer = timer(this.REFRESH_INTERVAL, this.REFRESH_INTERVAL);
      this.tokenCache = myTimer.pipe(
        takeUntil(stopTimer),
        switchMap(() => this.refreshToken()),
        shareReplay(this.CACHE_SIZE)
      );
      this.tokenSubscription = this.tokenCache.subscribe({
        next: (res) => {
          this.userToken = res.jwt;
          sessionStorage.setItem('token',this.userToken)
        
        },
        error: (err) => {
          console.error(err);
          this.clear();
          
        },
      });
    }
  }

  private refreshToken() {
    console.info('Refresh token requested in Token service');
  
    const currentUser =sessionStorage.getItem('token');
    return this._service.commonMethod(REFRESH_TOKEN + '?token='+ currentUser, GET);
  }

  clear() {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
    if (this.timer) {
      this.timer.next(true);
     
    }

   
    this._service.logout();
  }
}
