// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  cloudFrontUrl: 'https://de4nfar4wtruu.cloudfront.net/',//prod
  cloudFrontUrlCountryImgs: 'https://dmxspcinzry07.cloudfront.net/',
  apiUrl:'https://admin-api.onfusiondemo.cc/api/v1/',//prod
  dateFormat: 'MMM d, y h:mm a',
  overifyApiUrl:'https://o-verifyapi.onfusiondemo.cc/',
 

  
  



froalaKey:'mPD4tD2G2F1H1H1C3B1lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2E1C5F1G2F1H4C10==',

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

