import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
isLoading = new BehaviorSubject<boolean>(false);
  constructor() { }
  showWarningMessage(message: string, helpText?: string) {
    return Swal.fire({
      title: message,
      html: helpText,
      icon: 'warning',
      heightAuto: false,
      confirmButtonColor: '#FE7A00',
      confirmButtonText: 'OK',
    
      allowOutsideClick:false
    });
  }
  trimCkEditorContent(content: string) {
    return content.replace(
      /^((\n)+|(<p>(&[^&]*;(\s*))+<\/p>))+|(\n|(<p>(&[^&]*;(\s*))+<\/p>))+$/g,
      ''
    ); // Starting/ending either with space or new line
  }
  isEditorContainsNoData(content: string) {
    const editorData = this.trimCkEditorContent(content);
    return (
      editorData === '' ||
      editorData === '<p></p>' ||
      editorData === '<p></p>\n'
    );
  }
  validateUploadedFile(
    file: File,
    fileFormats: string[],
    maxSize = 2000,
    skipSizeValidation?: boolean
  ): string | null {
    if (file) {
      if (fileFormats.indexOf(file.type) > -1) {
        if (!skipSizeValidation && file.size / 1024 > maxSize) {
          this.showWarningMessage(
            `Uploaded file exceeds maximum size of ${maxSize / 1000}MB`
          );
          return null;
        }
        return file.type;
      } else {
        const allowedFileTypes = fileFormats.map((type) => {
          const fileType = type.split('/')[1].toUpperCase();
          switch (fileType) {
            case 'text/plain':
              return 'TXT';
            case 'image/svg+xml':
              return 'SVG';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'DOCX';
            default:
              return fileType;
          }
        });
        Swal.fire({
          title:`Only ${allowedFileTypes} file types are allowed`,
          icon:'warning',
          allowOutsideClick:false
        }
          
          // 'Please upload valid file format',
          // `Only ${allowedFileTypes} file types are allowed`,
          // 'warning'
        );
        return null;
      }
    }
    return null;
  }
 
  showLoader(){
    this.isLoading.next(true);
  }
  hideLoader(){
   this.isLoading.next(false);
  }

  //prevent enter Event

  stopEnterEvent(event:any){
    if(event.keyCode == 13){
      event.preventDefault();
  }
  }
}
