// HTTP Methods
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

// API Response Messages
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';
export const OK = 'OK';


// Login related
export const LOGIN_ENDPOINT = 'login/user';
export const RESET_PASSWORD_ENDPOINT = 'user/updatePassword';
export const RESET_ENDPOINT = 'login/user/resetPassword';
export const FORGOT_PASSWORD = 'login/forgotpasswords/';
export const PROFILE_EDIT = 'user/editProfile';
export const USER_PROFILE_DETAILS = 'user/getprofiledata';
export const REFRESH_TOKEN = 'user/refreshToken';
export const LOGOUT_ENDPOINT = 'user/logout/';
export const VALIDATE_FORGOT_ENDPOINT = 'login/user/mailtokenValid';

// cunstomer-controller/custDetailsByCustId

export const define_Product_Price = {
  GET_ALL_PRODUCTS: 'product/get-by-pagination-price',
  GET_PRICE_DETAILS: 'product/priceDetails',
  GETS_PRODUCT: 'product/productNames/getAllActiveProductsNames',
  GET_PRODUCT_DETAILS_BYID: 'product/ProductPriceDetailsByProductId',
  PRODUCT_PRICE_SAVE: 'product/productPrice/save',
  PUT_PRODUCT_PRICE_UPDATE: 'product/updatePrice',
  DELETE_PRODUCT_PRICE_PACKAGE: 'product/deleteProductPrice',
  PRODUCT_PRICE_ACTIVITY_HISTORY:
    'product/get-by-paginationbyactivityhistoryPrice',
    FEATURES_PRODUCTS_LIST:'product/productNames/getAllproductsForFeatures',
  PRODUCT_DETAILS_BY_ID:'product/priceDetails?productId='
};

//Products related
export const ProductApiObject = {
  PRODUCT_VERSIONS: 'product/historyVersion',
  PRODUCT_ADD_ENDPOINT: 'product/create',
  PRODUCT_VIEW_ENDPOINT: 'product/details-by-id',
  PRODUCT_LIST_ENDPOINT: 'product/get-by-pagination',
  PRODUCT_UPDATE_ENDPOINT: 'product/update-product',
  GET_ALL_PRODUCTS: 'product/names',
  UPDATE_PRODUCTS_FILES: 'product/updateFile',
  PRODUCT_ACTIVITY_HISTORY: 'product/get-by-paginationbyactivityhistory',
  GET_PRODUCT_WITHOUT_PACKAGE: 'product/getWithOutPackageProductNames',
};

// Users related

export const UserApiObject = {
  GET_ALL_PRODUCTS: 'user/get-all-product-details',
  GET_ALL_USER_MAPPED_PRODUCTS:'user/user-mapped-products',
  GET_ALL_ACTIVE_PRODUCTS: 'user/getAllActiveProductsNames',
  GET_ROLE_DETAILS: 'user/get-role-details',
  CREATE_USER: 'user/createUser/save',
  GENERATE_PASSWORD: 'user/userGeneratePasswordLink',
  USER_LIST_ENDPOINT: 'user/getAllUsersPage',
  USERS_VIEW_ENDPOINT: 'user/get-user-details',
  USERS_UPDATE_ENDPOINT: 'user/update-user',
  USERS_ACTIVITY_HISTORY: 'user/get-by-paginationbyactivityhistoryusers',
};

// Roles related
export const RolesApiObject = {
  ROLE_VIEW_ENDPOINT: 'user/roleManage/get-all-roles',
  CREATE_ROLE_ENDPOINT: 'user/roleManage/addRole',
  GET_ROLE_MODULE_BY_PRODUCTID: 'user/roleManage/components',
  VIEW_ROLE_ENDPOINT: 'user/roleManage/roleView',
  UPDATE_ROLE_ENDPOINT: 'user/roleManage/updateRole',
  GET_PERMISSION_BY_ROLE_NAME: 'user/roleManage/userPermissions',
  GET_ROLE_NAMES: 'user/roleManage/rolename',
  ROLES_ACTIVIY_HISTORY:
    'user/roleManage/get-by-paginationbyactivityhistoryrole',
};

export const CustomerApiObject = {
  LIST_OF_CUSTOMERS: 'customerPortolio/list',
  VIEW_CUSTOMER_PORTFOLIIO: 'customer/details',
  COUNTRIES_LIST: 'customer/countries',
  DELETED_CUSTOMERS_LIST: 'customer/getalldeletedCustomerslist',
  DELETE_CUSTOMER: 'customer/deleteCustomer/',
  DELETE_CUSTOMER_DESCRIPTION: 'customer/deletecustomersave',
  UPDATE_CUSTOMER: 'customerPortolio/update',
  CUSTOMER_HISTORY: 'customer/subCusHistory/list',
  CUSTOMER_ACTIVITY_HISTORY: 'customerPortolio/listOfHistory',
  SEND_MAIL:"customer/passwordAndMail/sendMail",
  GET_PACKAGES_PLANS:'customer/prdSubList/packagePlanDetails',
  REGISTRATION_MONITORING:'customerPortolio/recentRegisteredCustList',
  DELETE_REGISTRAION_MONITORING:'customerPortolio/deleteRecentCustomer'
};

// bonusmanagemnet
export const BonusRecordsObject = {
  LIST_OF_BONUS_RECORDS: 'bonus/list',
  ADD_BONUS: 'bonus/save',
  UPDATE_BONUS: 'bonus/update',
};

export const BonusApiObject = {
  VIEW_BONUS_ENDPOINT: 'bonus/byId',
};

//ordermanagement
export const OrderManagementObject = {
  LIST_OF_PRODUCT_ORDER: 'order/getAllorderDetails',
  GET_ORDER_DETAILS_ENDPOINT: 'order/getorderDetailsByOrderId/',
};

export const FaqObj = {
  LIST_OF_FAQ: 'content/qa/getListOfFaqs',
  FEEDBACK_LIST: 'content/qa/getListOfFaqsfeedback',
  ADD_FAQ: 'content/qa/addFaq',
  GET_FAQ_HISTORY_BY_ID: 'content/qa/getfaqhistorybyifid?faqId=',
  GET_VERSION: 'content/getlatestversion',
  UPdate_Faq: 'content/qa/updatefaq',
  FAQ_EXCEL_DOWNLOAD: 'content/qa/download-excel',
  FAQ_CSV_DOWNLOAD: 'content/qa/export-csv',
  FAQ_PDF_DOWNLOAD: 'content/qa/pdfdownlaod',
  UPLOAD_CSV_FAQ: 'content/qa/uploadCsv',
  FAQ_HISTORY_LIST: 'content/qa/get-by-qaHistory',
  DELETE_FAQ: 'content/qa/deleteQas',
};
//Footer
export const FooterObject = {
  List_Of_FooterDocuments: 'content/footer/getAllFooterList',
  Edit_Footer: 'content/footer/edit',
  View_Footer: 'content/getDocument/',
  Footer_History: 'content/getAllDocumentHistory',
};

//Banner Management
export const BannerObject = {
  ADD_BANNER: 'content/banners/save',
  EDIT_BANNER: 'content/banners/update',
  BANNER_LIST: 'content/banners/getAll',
  GET_BANNER_BY_ID: 'content/banners/byId',
  BANNER_HISTORY: 'content/banners/historyList',
};
//UserManual Management
export const UserManualObject = {
  ADD_USERMANUAL: 'content/usermanual/addUserManual',
  GET_VERSION: 'content/usermanual/getversion',
  USER_MANUAL_HISTORY: 'content/usermanual/userManualHistory',
  USER_MANUAL_LIST: 'content/usermanual/getAllList',
  UPDATE_USER_MANUAL: 'content/usermanual/updateUserManual',
  DOWNLOAD_IMAGE: 'content/usermanual/download',
  DOWNLOAD_EXCEL: 'content/usermanual/downloadExcelFIle',
  DOWNLOAD_CSV: 'content/usermanual/downloadCsvFIle',
};

export const dashboardObj = {
  getAllCountries: 'dashboard/getAllCountries',
  ObsDashbord: 'dashboard/obsDashReport',
  OFounderDashboard: 'dashboard/ofoudersDashboard',
  OConnectDashboard: 'dashboard/oconnetDashboard',
  onetDashboard: 'dashboard/onetDashboard',
  otrimDashboard: 'dashboard/otrimDashboard',
  downloadCSV: 'dashboard/export-csv',
  productWiseDataByCountry:'dashboard/get-map-dashboard-count'
};

//Transaction Management
export const TransacObj = {
  getAllTransacList: 'order/transaction/getAll',
  getTransactionById: 'order/transaction/byId/',
};

export const VideoObj = {
  getAllVideoList: 'content/videos/all',
  saveVideo: 'content/videos/save',
  getAllVideoRecById: 'content/videos/byId',
  getAllVideoHistory: 'content/videos/history',
};

//news Management

export const newsObj = {
  getAllNewsList: 'content/news/list',
  addNews: 'content/news/save',
  viewNewsById: 'content/news/byId',
  updateNews: 'content/news/update',
  newsHistoryList: 'content/news/historyList',
  DELETE_NEWS:'content/news/deletenews'
};
//link management
export const linkObj = {
  getCategories: 'content/links/getCategories',
  addLinks: 'content/links/save',
  getSeriesLinkId: 'content/links/getSeriesLinkId',
  updateNews: 'content/news/update',
  linkList: 'content/links/getLinksList',
  viewLink: 'content/links/view',
  GET_LINKS_ACTIVITY_HISTORY: 'content/links/getLinksHistoryDataByPagination',
  updateLink: 'content/links/update',
};

//Module Management
export const moduleObj = {
  getModuleList: 'content/modules/getallmodulesList',
  getPosition: 'content/modules/getpositonNumber',
  addModule: 'content/modules/addModules',
  reorderPosition: 'content/modules/reorderposition',
  viewModule: 'content/modules/getModuleview',
  editModule: 'content/modules/updateModules',

  moduleActivityHistory: 'content/modules/modulesHistorylistbyPagination',
};

//Affiliate Management
export const affiliateObj = {
  GET_AFFILIATE_LIST: 'customer/affiliate/search',
  VIEW_AFFILIATE: 'customer/affiliate/byId/',
  SAVE_AFFILIATE: 'customer/affiliate/update',
  AFFLIATEHISTRY: 'customer/affiliate/affiliateActivityHistory',
  CSV_DOWNLOAD_AFFILIATE: 'customer/affiliate/export-csv-affiliate',
  DOWNLOAD_PDF_AFFILIATE: 'customer/affiliate/pdfdownlaod',
  DOWNLOAD_EXCEL: 'customer/affiliate/download-excel',
};

//broadcast Managemnet
export const broadcastObj = {
  BROADCAST_LIST: 'customer/boardcast/getbroadcastmailsList',
  SEND_MAIL: 'customer/boardcast/send-broadcast-mails',
  VIEW_MAIL: '',
  GET_DATA_BY_COUNTRY: 'customer/boardcast/getboardcastdashboardcount',
};

//Video Promotions grid
export const VideoPromoObj = {
  getAllVideoPromo: 'content/videoPromotion/getAll',
  saveVideoPromo: 'content/videoPromotion/saveVideoPromotions',
  editVideoPromo: 'content/videoPromotion/editVideo',
  getAllVideoRecById: 'content/videoPromotion/videoPromotionById',
  getAllVideoHistory: 'content/videoPromotion/getAllHistory',
  getProduct: 'product/productNames/getAllActiveProductsNames',
};

//Invitation template
export const InvitationObj = {
  getAllInviTemp: 'content/Templates/all',
  saveInviTempPromo: 'content/Templates/save',
  editInviTempPromo: 'content/Templates/update',
  getAllInviTempRecById: 'content/Templates/invitId?invitId=',
  getAllInviTempHistory: 'content/Templates/history',
  getProduct: 'product/productNames/getAllActiveProductsNames',
};

//Refund Management
export const RefundObject = {
  GET_ALL_REFUND_LIST: 'customer/refund/getrefundlistData',
  REFUND_VIEW: 'customer/refund/getrefundDataById',
  REFUND_UPDATE: 'customer/refund/updateRefundStatus',
  REFUND_HISTORY: 'customer/refund/getrefundHistoryBypagination',
};

export const uploadDocument = {
  GET_ALL_DOCUMENT_LIST: 'content/guide/getguidesList',
  DOCUMENT_VIEW: 'customer/refund/getrefundDataById',
  DOCUMENT_ADD: 'content/guide/savePdfOrVideo',
  GET_DOC_VERSION: 'content/guide/getdocversion',
  GET_DOC_BY_ID: 'content/guide/getGuiderecord?guideId=',
  DOCUMENT_UPDATE: 'content/guide/updatePdfOrVideo',
  DOCUMENT_HISTORY: 'content/guide/getguidesHistorydetails',
};
export const MASTER_DATA = {
  PACKAGE_LIST: 'product/package/getAll',
  ADD_PACKAGE: 'product/package/save',
  EDIT_PACKAGE: 'product/package/update',
  PACKAGE_HISTORY: 'product/package/getHistories',
  GET_PACKAGE_BY_ID: 'product/package/getProductById',
  FEATURE_LIST: 'product/getfeatureslist',
  ADD_FEATURE: 'product/createFeature',
  EDIT_FEATURE: 'product/updateFeature',
  FEATURE_HISTORY: 'product/getfeatureshistorylist',
  GET_FEATURE_BY_ID: 'product/getFeaturesbyProductId',
};
//subscription management
export const subsManagementObject = {
  GET_SUBSCRIPTIONS: 'customer/prdSubList/get',
  Get_PLANS: 'product/package/getPlans',
  GET_PACKAGES: 'product/package/getPackages'
};

//ResetPwd managemnet
export const resetPwdObject = {
  RESET_PWD_LIST:"customer/passwordAndMail/getpasswordReqdata",
  SEND_RESET_PWD:"customer/passwordAndMail/resetPassword"
}

//Overify
export const overifyObj = {
   REPORTS_LIST:'customer/overify/overifydata',
   ADMIN_REPORT:'customer/overify/overifyadmindata',
   ADMIN_CSV:'customer/overify/export-csv-overify-admin',
   VIEW_APPROVE:'customer/overify/viewbyuid?uid=',
   APPROVE_OR_REJECT:'customer/overify/approve-uid',
   REPORT_VIEW:'customer/overify/byKycId',
   VERIFICATION_VIEW_BY_ID:'customer/overify/Verifactionviewbyuid',
   COUNTRIES_LIST:'customer/overify/getAllOverifyCountries',
   GET_DETAILS_BY_COUNTRY_ID:'customer/overify/countrybyId',
   OVERIFY_UPDATE_STATUS:'customer/overify/updateStatus?status=upload&',
   SEND_UPLOADED_DOCUMENTS_RESULT:"customer/overify/updateStatus",
   DASHBOARD_DATA:'customer/overify/overifyDashReport',
   DASHBOARD_WORLDWIDE_DATA:'customer/overify/overifyWorldMap',
   OVERIFY_REPORT_HISTORY:'customer/overify/historybyKycId'
}
//Commission Management
export const commissionObject = {
    COMMISSION_LIST:'customer/commission/getcommissionlist',
    REFFERAL_USERS_LIST:'customer/commission/getreferalcommissionlist',
    DOWNLOAD_CSV:"customer/commission/export-csv-commission",
    DOWNLOAD_EXCEL:'customer/commission/download-excel',
    REFFERAL_DOWNLOAD_CSV:'customer/commission/export-csv-referal',
    REFFERAL_DOWNLOAD_EXCEL:'customer/commission/download-excel-referal'
}
export const invoicesObject = {
  INVOICES_LIST:'customer/invoicemangement/getinvicemanagementdata',
  REFFERAL_USERS_LIST:'customer/commission/getreferalcommissionlist',
  DOWNLOAD_CSV:"customer/commission/export-csv-commission",
  DOWNLOAD_EXCEL:'customer/commission/download-excel',
  REFFERAL_DOWNLOAD_CSV:'customer/commission/export-csv-referal',
  REFFERAL_DOWNLOAD_EXCEL:'customer/commission/download-excel-referal',
  DOWNLOAD_PDF:'customer/invoicemangement/invoicepath'

}

//ModulesAndFeatures
export const ModulesAndFeaturesObject = {
  ADD_MODULE:'product/moduleFeature/addModule',
  GET_MODULES_BY_PRODUCT_ID:'product/moduleFeature/getModulesByProduct',
  UPDATE_MODULE:'product/moduleFeature/updateModule',
  DELETE_MODULE:'product/moduleFeature/deleteModule',
  DELETE_MODULE_WITH_FEATURES:'product/moduleFeature/deleteModulewithFeatures',
  GET_FEATURES_BY_MODULE_ID:'product/moduleFeature/getFeaturesByModuleId?moduleId=',
  ADD_FEATURE:"product/moduleFeature/addFeature",
  UPDATE_FEATURE:'product/moduleFeature/updateFeature',
  FEATURES_LIST:"product/getfeatureslist",
  DELETE_FEATURES:"product/moduleFeature/deleteFeatures",
  GET_PLANS:"product/moduleFeature/getPlans?productId="
}

//Feature Settings
export const FeatureSettings = {
  Get_FEATURES_LIST:"product/moduleFeature/fetchfeatureSettings?productId=",
  SAVE_FEATURE_SETTINGS:'product/moduleFeature/addfeatureSettings',
  GET_FEATURES_WITH_PRODUCTID:'product/moduleFeature/getfeatureSettingsbyProductId?productId=',
  UPDATE_FEATURE_SETTINGS:'product/moduleFeature/editfeatureSettings',
  GET_PLANS_BY_MAPPED_FEATURE_SETTINGS:'product/moduleFeature/getPlansByMappedFeatureSetting?productId=',
 
}
//BillingPlan
export const BillingPlan = {
  UPDATE_BILLING_PLAN:'product/moduleFeature/updatePlan',
  GET_PLANS:'product/getallPlans'
}


//package-setting
export const PackageSettings = {
  SAVE_PLAN:"product/productPrice/save",
  DELETE_SETTING:"product/deleteProductPrice?productId=",
  GET_PRODUCT_BY_PACKAGE:"product/moduleFeature/getProductsByModuleAndFeatureMapped",
  GET_UPGRADE_DOWNGRADE:"product/pak_plan_upDwnCancel/fetchUpgradeDownGradeSettingsByProductId?productId=",
  GET_UPGRADE_DOWNGRADE_EDIT:"product/pak_plan_upDwnCancel/getUpgradeOrDownGradeDataByProductId?productId=",
  GET_PRO_RATED:"product/pak_plan_upDwnCancel/getproRatedBasics",
  SAVE_UPGRADE:"product/pak_plan_upDwnCancel/saveUpGradeOrDownGrade"
}

//Tax Settings
export const TaxSettings = {
  CREATE_TAX:'customer/tax/savetax',
  TAX_LIST:'customer/tax/taxlist',
  TAX_VIEW:'customer/tax/viwebytaxid?country='
}

//Payment Gateway
export const PaymentGateway = {
  GATEWAY_LIST:'product/paymentgatewaylist',
  UPDATE_PAYMENT_GATEWAY_STATUS:'product/updatepaymentgatewaystatus'
}

// SPECIAL_CHARACTERS
export const ASCII = [];
export const AVTAR_ALLOWED_VIDEO_FILES = ['jpeg', 'png', 'jpg'];
export const AVTAR_ALLOWED_IMAGE_FILES = ['jpeg', 'png', 'jpg'];
export const ALLOWED_VIDEO_FILES = ['mp4','avi','webm','mov'];
export const AVTAR_ALLOWED_DOCUMENT_FILES = [
  'jpeg',
  'png',
  'jpg',
  '&' + '' + ' Mp4',
];
export const AVTAR_SVG_DOCUMENT_FILES = ['svg'];
export const ALLOWED_DOCUMENT_FILES = ['pdf'];
export const SPECIAL_CHARACTERS: string[] = [' ', '.', '_', '-'];
// export const SPL_CHAR = [' ', '.', '_', '-'];

for (let i = 0; i <= 47; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}
for (let i = 58; i <= 64; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}

for (let i = 91; i <= 96; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}
for (let i = 123; i <= 255; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}

// NUMBERS
export const NUMBERS: string[] = [];
for (let i = 48; i <= 57; i++) {
  NUMBERS.push(ASCII[i]);
}

SPECIAL_CHARACTERS.concat(['Alt', '®', '©', '™']);

export const NAVIGATION_KEYS = [
  'Backspace',
  'Delete',
  'Tab',
  'Escape',
  'Enter',
  'Home',
  'End',
  'ArrowLeft',
  'ArrowRight',
  'Clear',
  'Copy',
  'Paste',
];

export const PAGINATION_NUMBERS  = [
  { name: "100", value: 100 },
  { name: "500", value: 500 },
  { name: "1000", value: 1000 },
];
