import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
private userInfo: any = new BehaviorSubject<any>(null);
private permissionInfo:any = new BehaviorSubject<any>(null);
storageKey = ' ';

  constructor() { }

  setItem(name: string, value: string, isJson = false) {
    let updatedValue = value;
    if (isJson) {
      updatedValue = JSON.stringify(updatedValue);
    }
    sessionStorage.setItem(name, updatedValue);
  }

  getItem(key: string, isJson = false) {
    const value: any = sessionStorage.getItem(key);
    if (isJson) {
      return value == 'undefined' ? '' : JSON.parse(value);
    }
    return value;
  }

  getUserInfo() {
    return this.userInfo.asObservable();
   
  }

  setUserInfo(data: any) {
    this.userInfo.next(data);
    
  }
  getPermissionInfo() {
   return this.permissionInfo.asObservable();
   
  }

  setPermissionInfo(data: any) {
    this.permissionInfo.next(data);
    
  }
  // environment.storageKey;
  getLoggedInUser(key: string, json?: boolean) {
    if (json) {
      const user: any = sessionStorage.getItem(this.storageKey + key);
      return user == 'undefined' ? '' : user;
    } else sessionStorage.getItem(this.storageKey +  key);
  }

  setLoggedInUser(key: string, data: any, json: boolean) {
    if (json)
      sessionStorage.setItem(this.storageKey +  key, data);
    else sessionStorage.setItem(this.storageKey  + key, data);
  }
  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clearStorage() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('roleId');
    
    sessionStorage.clear();
  }
  removeUser(key: string) {
    localStorage.removeItem(this.storageKey + key);
    sessionStorage.removeItem(this.storageKey  + key);
   
  }

  isUserLoggedIn() {
    return this.getItem('token') ? true : false;
  }
  isLoggedIn() {
    return sessionStorage.getItem('token') ? true : false;
  }

}
