import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './modules/_helpers/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from './modules/core/http/api.service';
// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ApiInterceptor } from './modules/core/interceptors/api.interceptor';
import { LottieModule } from 'ngx-lottie';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    // FroalaEditorModule.forRoot(),
    // FroalaViewModule.forRoot(),
    // NgxDaterangepickerMd.forRoot(),
    LottieModule.forRoot({
      player: playerFactory
    }),

  ],
  providers: [ApiService,{
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi:true
  }],

  bootstrap: [AppComponent]
})
export class AppModule { }
