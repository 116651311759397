import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { GET, LOGIN_ENDPOINT, LOGOUT_ENDPOINT, POST, REFRESH_TOKEN } from '../constants/constants';
import * as dayjs from 'dayjs';
//@ts-ignore
import utc from 'dayjs/plugin/utc';
import { ToastrService } from 'ngx-toastr';
dayjs.extend(utc)

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public productId = new BehaviorSubject('')

 apiUrl = environment.apiUrl;
 newArr:any
//  dayjsUtcs = require('dayjs/plugin/utc')


  constructor(
    private _http:HttpClient,
    private _ls:LocalStorageService,
    private router:Router,
    private matDialog:MatDialog,
    private toaster:ToastrService
    
    ) {  
      // dayjs.extend(this.dayjsUtcs)

    }

commonMethod(
  url:string,
  data?:any,
  method?:string,
  options?:any,
  url_type?:any
): Observable<any> {
    const endPoint = url_type ? `${url_type}${url}`  : `${this.apiUrl}${url}`;
    const body = data || '';
    const reqOptions = options || '';

    switch(method){
        case 'POST':
          return this._http.post(endPoint,body,reqOptions);
        case 'PUT':
          return this._http.put(endPoint,body,reqOptions) ;
        case 'DELETE':
          return this._http.delete(endPoint,body);
        default:
          return this._http.get(endPoint,body);

    }

}
downloadCsv(endPoint: string, params: any, fileName?: string) {
  this.commonMethod(endPoint, params, POST, {
    observe: 'body',
    responseType: 'blob' as 'json',
  }).subscribe({
    next: (result: Blob) => {
    if (result) {
      saveAs(result || '', fileName);
      this.checkFileName(result.type)
    }
    
    // HttpResponse might return null if nothing is returned so passed empty string
  }, error: (err:HttpErrorResponse) => {
    console.error(err);
    this.toaster.error(err.message,"Error",{closeButton:true})
  },
  });
}
  checkFileName(fileName?:string) {
    this.toaster.clear()
    if(fileName?.includes('csv')) {
      this.toaster.success("CSV file downloaded successfully.", "Success", { closeButton: true })
      return
    }
   else if(fileName?.includes('pdf')) {
    this.toaster.success("PDF file downloaded successfully.", "Success", { closeButton: true })
    return
    }
    else if(fileName?.includes('spreadsheetml')) {
      this.toaster.success("Excel file downloaded successfully.", "Success", { closeButton: true })
      return
    }
 
  }
logout() {

  if (this._ls.isLoggedIn()) {
  
    const currentUser = sessionStorage.getItem('token');
           this.commonMethod(LOGOUT_ENDPOINT + currentUser ,GET).subscribe({
              complete: () => {
                this.clearStorageAndLogout();
              }
           })
  }
  this.clearStorageAndLogout();
}
private clearStorageAndLogout(){
    this._ls.clearStorage();
  this.router.navigate(['auth/login']);
  return;
}
closeAllPopups() {
  this.matDialog.closeAll();
 
}
refreshToken() {
 
  const currentUser = sessionStorage.getItem('token');
  this.commonMethod(REFRESH_TOKEN +'?token='+ currentUser , GET).subscribe({
    next: (result: any) => {
      sessionStorage.setItem('token',result.jwt)
  
    },
    error: (err) => {
      console.error(err);
    },
  });
}

// method for downlaoding csv and converting utc date format to local time (Customer del / transcation) 
  downloadCsvWithLocalTime(endPoint: string, params: any, fileName: string) {
    this.commonMethod(endPoint, params, POST, {
      observe: 'body',
      responseType: 'text',
    }).subscribe((resp: any) => {
      let data = resp
      if(!fileName.toLowerCase().includes("pdf")){

      let filtData: any;
      data = resp.split("\r")
      var stillUtc:any
      var stillUtc1:any
      var local:any
      let utcDate: any;
      let utcSdate:any
      for (let i = 1; i < data.length - 1; i++) {
        filtData = data[i].trim();
        switch (fileName) { // to fetch date and time from object
          case 'DeletedCustomersCsv':
            utcDate = filtData?.split(",")[9]
            break;
          case 'TransactionDetailsCsv':
            utcDate = filtData?.split(",")[6]
            break;
          case 'OrderDetailsCsv':
            utcDate = filtData?.split(",")[7]
            break;
          case 'affiliateCsv':
            utcDate = filtData?.split(",")[5]
            break;
          case 'refundCustomerCsv':
            utcDate = filtData?.split(",")[7]
            utcSdate = filtData?.split(",")[8]
            break;
        }
        utcDate = utcDate.replace(/[^0-9,:. -T]/g, "").replace(/,/g, "").replace(/\s+/g, " ");
        if(utcSdate != undefined){
          utcSdate = utcSdate.replace(/[^0-9,:. -T]/g, "").replace(/,/g, "").replace(/\s+/g, " ");
        }
        if (utcDate !== "") {
        
          stillUtc = dayjs.utc(utcDate).toDate();
          local = dayjs(stillUtc).local().format('MMM DD YYYY hh:mm a');
          this.newArr = data[i].split(",")
          if(utcSdate != undefined){
            stillUtc1 = dayjs.utc(utcSdate).toDate();
            local = dayjs(stillUtc1).local().format('MMM DD YYYY hh:mm a');
          }
          switch (fileName) { // to replace converted date and time in the object
            case 'DeletedCustomersCsv':
              this.newArr.pop()
              this.newArr += `, ${local}`
              break;
            case 'TransactionDetailsCsv':
              this.newArr.splice(6, 1," "+local)
              break;
            case 'OrderDetailsCsv':
              this.newArr.splice(7, 1, " " + local)
              break;
            case 'affiliateCsv':
              this.newArr.splice(5, 1, " " + local)
              break;  
            case 'refundCustomerCsv':
              this.newArr.splice(7, 1, " " + local)
              this.newArr.splice(8, 1, " " + local)
              break;
          }
          data[i] = this.newArr; // replace the old date value with the new one
        }
      }

    }
      if (data) {
        var blob = new Blob([data.toString()], { type: "text/plain;charset=utf-8" });
        fileName.toLowerCase().includes("csv") ? saveAs(blob || '', fileName + ".csv") : saveAs(blob || '', fileName + ".pdf") 
      }
    });
  }

  private _unsavedChanges = false;

  get unsavedChanges(): boolean {
    return this._unsavedChanges;
  }

  setUnsavedChanges(value: boolean): void {
    this._unsavedChanges = value;
  }
}