import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ApiService } from './modules/core/http/api.service';
import { LocalStorageService } from './modules/shared/services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'oes-admin-new';
  userActivity!: any;
  userInactiveState: Subject<any> = new Subject();
  user: any;
  constructor(private _ls: LocalStorageService, private _service: ApiService) {
   
    this.setTimeout();
    this.userInactiveState.subscribe(() => {
      let timerInterval: any;
      Swal.fire({
        icon: 'warning',
        // iconHtml: '<img src="/assets/images/icons/alert-toaster.svg">',
        html: "Your session will expire in <b></b> seconds. Please click 'OK' if you wish to continue the session.",
        timer: 1 * 60 * 1000,
        timerProgressBar: true,
        confirmButtonText: 'OK',
        heightAuto: false,
        allowOutsideClick: false,
        confirmButtonColor: '#FE7A00',
        didOpen: () => {
          
          const b = Swal.getHtmlContainer()?.querySelector('b');
          if (b) {
            timerInterval = setInterval(() => {
              b.textContent = Math.round(
                (Swal.getTimerLeft() || 0) / 1000
              ).toString();
            }, 100);
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        switch (result.dismiss) {
          case Swal.DismissReason.timer:
            console.info('Session expired due to inactivity');
           
            this._service.closeAllPopups();
            this._service.logout();
        
            break;
          default:
            console.info('continue the session');
            this._service.refreshToken();
            this.refresh();
        }
      });
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (this._ls.isLoggedIn()) {
        this.userInactiveState.next(undefined);
      }
    }, 30 * 60 * 1000); // idle time 30 min
  }

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  refresh() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
