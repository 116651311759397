import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ApiService } from '../http/api.service';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { UtilService } from '../../shared/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  
  private requests: HttpRequest<any>[] = [];

  constructor(
    private ls:LocalStorageService,
    private apiService:ApiService,
   private utilService:UtilService,
   private toaster:ToastrService,
   private deviceService: DeviceDetectorService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requests.push(request);

    this.utilService.showLoader();
  
     const currentUser = sessionStorage.getItem('token');
    const deviceInfo = this.deviceService.getDeviceInfo();
  

      const headers = {} as { [key: string]: any };
      headers['browserName'] = `${deviceInfo.browser}`;
      headers['browserVersion'] = `${deviceInfo.browser_version}`;
      headers['deviceType'] = `${deviceInfo.deviceType}`;
      headers['osName'] = `${deviceInfo.os}`;
      headers['osVersion'] = `${deviceInfo.os_version}`;
      headers['enId'] = sessionStorage.getItem('userId');
      if(currentUser){
          headers['authorization'] = 'Bearer ' + currentUser;
       
          request = request.clone({
            setHeaders:headers
          })
      } 


   
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: event.body });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.error('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.error('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.toaster.clear();
        switch (error.status) {
          case 400:
            // Swal.fire('Error!', error?.error?.exMsg, 'error');
            break;
          case 401:
            // Swal.fire('Please login to continue', '', 'error');
            this.toaster.error(error?.error?.message == undefined ? "Session Timeout Please login again" : error?.error?.message,"Error",{closeButton:true})

           this.apiService.logout();
            break;
          case 404:
            // Swal.fire('Error!', `${error?.error?.data}`, 'error');
            this.toaster.error(error?.error?.data,"Error",{closeButton:true})

            break;
          case 403:
            console.error('Getting 403 error while accessing ' + request.url);
            // Swal.fire('Session expired', 'Please login to continue', 'warning');
            this.toaster.warning('Session expired',"Error",{closeButton:true})

            // this._service.closeAllPopups();
            // this.apiService.logout();
            break;
          case 415:
            // Swal.fire('File type is not supported', '', 'error');
            this.toaster.error('File type is not supported',"Error",{closeButton:true})

            break;
          case 417:
            // Swal.fire(error?.error?.message, '', 'warning');
            this.toaster.warning(error?.error?.message,"Error",{closeButton:true})
            break;
            case 500:
              this.toaster.error( error?.error?.message,"Error",{closeButton:true})
              break;

        }
        console.error(errorMsg);
        return throwError(() => error);
      }),
      finalize(() => {
        const index = this.requests.indexOf(request);
        this.requests.splice(index, 1);

        if (this.requests.length === 0) {
          this.utilService.hideLoader();
        }
      })
    );
  }
}
